import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Datatable from "./datatable.jsx";
import {
  getAllProductRequestsRedux,
  getAllUsersRedux,
  getAllD2DRatesRedux,
  setProductRequestRedux,
} from "../../actions/index";
import { Link } from "react-router-dom";
import UpdateRequestModal from "./updateRequestModal.jsx";
import man from "../../components/products/physical/plus image.jpeg";
import { connect } from "react-redux";
import { Search } from "react-feather";
import { HashLoader, CircleLoader } from "react-spinners";
import { uploadImage } from "../../firebase/firebase.utils.js";
export class New extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      searchFor: "",
      toggleModal: true,
      toggleDeleteModal: true,
      order: null,
      imageUrl: man,
      date: new Date().toLocaleDateString("en-GB"),
      productQuantity: "",
      productUrl: "",
      productVariant: "",
      productType: "",
      country: "",
      store: "",
      image_loader: false,
      submit_loader: false,
      cursor: -1,
      customer: "",
      customerUid: "",
      showSuggestion: false,
    };
  }

  componentDidMount = async () => {
    this.props.getAllUsersRedux();
    console.log(this.props.match.params.orderStatus);
    if (this.props.match.params.orderStatus === "new") {
      this.props.getAllProductRequestsRedux("Pending");
    } else if (this.props.match.params.orderStatus === "rates-given") {
      this.props.getAllProductRequestsRedux("Rates Provided");
    } else if (this.props.match.params.orderStatus === "purchased") {
      this.props.getAllProductRequestsRedux("Purchased");
    } else if (
      this.props.match.params.orderStatus === "received-in-warehouse"
    ) {
      this.props.getAllProductRequestsRedux("Received in Warehouse");
    } else if (this.props.match.params.orderStatus === "ready-for-fly") {
      this.props.getAllProductRequestsRedux("Ready for fly");
    } else if (this.props.match.params.orderStatus === "bangladesh-customs") {
      this.props.getAllProductRequestsRedux("Bangladesh customs");
    } else if (this.props.match.params.orderStatus === "bangladesh-customs") {
      this.props.getAllProductRequestsRedux("Bangladesh customs");
    } else if (this.props.match.params.orderStatus === "custom-released") {
      this.props.getAllProductRequestsRedux("Bangladesh warehouse");
    } else if (this.props.match.params.orderStatus === "paicart-warehouse") {
      this.props.getAllProductRequestsRedux("Paicart warehouse");
    } else if (this.props.match.params.orderStatus === "delivered") {
      this.props.getAllProductRequestsRedux("Delivered");
    } else if (this.props.match.params.orderStatus === "rejected") {
      this.props.getAllProductRequestsRedux("Reject");
    }
  };

  componentWillReceiveProps = (nextProps) => {
    console.log(nextProps.match.params.orderStatus);
    if (
      this.props.match.params.orderStatus !== nextProps.match.params.orderStatus
    ) {
      if (nextProps.match.params.orderStatus === "new") {
        this.props.getAllProductRequestsRedux("Pending");
      } else if (nextProps.match.params.orderStatus === "rates-given") {
        this.props.getAllProductRequestsRedux("Rates Provided");
      } else if (nextProps.match.params.orderStatus === "purchased") {
        this.props.getAllProductRequestsRedux("Purchased");
      } else if (
        nextProps.match.params.orderStatus === "received-in-warehouse"
      ) {
        this.props.getAllProductRequestsRedux("Received in Warehouse");
      } else if (nextProps.match.params.orderStatus === "ready-for-fly") {
        this.props.getAllProductRequestsRedux("Ready for fly");
      } else if (nextProps.match.params.orderStatus === "bangladesh-customs") {
        this.props.getAllProductRequestsRedux("Bangladesh customs");
      } else if (nextProps.match.params.orderStatus === "bangladesh-customs") {
        this.props.getAllProductRequestsRedux("Bangladesh customs");
      } else if (nextProps.match.params.orderStatus === "custom-released") {
        this.props.getAllProductRequestsRedux("Bangladesh warehouse");
      } else if (nextProps.match.params.orderStatus === "paicart-warehouse") {
        this.props.getAllProductRequestsRedux("Paicart warehouse");
      } else if (nextProps.match.params.orderStatus === "delivered") {
        this.props.getAllProductRequestsRedux("Delivered");
      } else if (nextProps.match.params.orderStatus === "rejected") {
        this.props.getAllProductRequestsRedux("Reject");
      }
    }
  };

  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      console.log(name);
      if (name == "country") {
        console.log(name);
        this.props.getAllD2DRatesRedux("air", this.state.country);
      }
    });
  };

  handleBookNowSubmit = async () => {
    if (this.state.customerUid == "") {
      alert("Please select a customer first.");
      return;
    }
    if (this.state.image_loader) {
      alert("Wait for the image to be uploaded!");
      return;
    }
    if (!this.state.productType || !this.state.country) {
      alert("Please select a product type.");
      return;
    }
    this.setState({ submit_loader: true });
    let date = new Date();
    const [productType, shippingRate] = this.state.productType.split("-");
    console.log(this.state.productType);
    console.log(productType);
    console.log(shippingRate);
    await this.props.setProductRequestRedux({
      ...this.state,
      date: date.toLocaleDateString("en-GB"),
      time: date.getTime(),
      userId: this.state.customerUid,
      displayName: `${this.state.customer}-${this.state.displayName}`,
      shippingRate,
      productType,
      shipmentStatusScore: 1,
      status: "Pending",
      orderStatus: "Pending",
    });
    this.setState({
      submit_loader: false,
    });

    this.setState({
      imageUrl: man,
      date: new Date().toLocaleDateString("en-GB"),
      productQuantity: "",
      productUrl: "",
      productVariant: "",
      image_loader: false,
      submit_loader: false,
      cursor: -1,
      customer: "",
      customerUid: "",
      showSuggestion: false,
    });
  };

  handleChangeCustomer = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, showSuggestion: true, cursor: -1 });
  };

  handleKeyDown = (e) => {
    const { cursor } = this.state;
    let result = [];
    if (this.state.customer) {
      const suggestionById = this.props.allUsers.filter((user) =>
        user.userId.includes(this.state.customer)
      );
      const suggestionByName = this.props.allUsers.filter(
        (user) =>
          user.displayName &&
          user.displayName
            .toLowerCase()
            .includes(this.state.customer.toLowerCase())
      );
      result = [...suggestionById, ...suggestionByName].slice(0, 10);

      // arrow up/down button should select next/previous list element
      if (e.keyCode === 38 && cursor > -1) {
        this.setState((prevState) => ({
          cursor: prevState.cursor - 1,
        }));
      } else if (e.keyCode === 40 && cursor < result.length - 1) {
        this.setState((prevState) => ({
          cursor: prevState.cursor + 1,
        }));
      } else if (e.keyCode === 13 && cursor > -1) {
        this.setState({
          customer: result[cursor].userId,
          customerUid: result[cursor].uid,
          displayName: result[cursor].displayName,
          showSuggestion: false,
        });
      }
    } else {
      result = [];
    }
  };

  renderShowSuggestion = () => {
    let suggestionArray = [];
    console.log(this.state.customer);
    console.log(this.props.allUsers);
    if (this.state.customer) {
      console.log(this.state.customer);
      const suggestionById = this.props.allUsers.filter((user) =>
        user.userId.includes(this.state.customer)
      );
      const suggestionByName = this.props.allUsers.filter(
        (user) =>
          user.displayName &&
          user.displayName
            .toLowerCase()
            .includes(this.state.customer.toLowerCase())
      );
      suggestionArray = [...suggestionById, ...suggestionByName];
      const uniqueUser = [...new Set(suggestionArray)];
      console.log(suggestionArray);
      return uniqueUser.slice(0, 10).map((user, index) => (
        <li
          key={user.userId}
          style={{
            minWidth: "195px",
            backgroundColor: this.state.cursor == index ? "gainsboro" : "white",
          }}
          onClick={() =>
            this.setState({
              customer: user.userId,
              customerUid: user.uid,
              displayName: user.displayName,
              showSuggestion: false,
              subCategory: `${user.userId}-${user.displayName}`,
            })
          }
        >
          {user.userId}-{user.displayName ? user.displayName.slice(0, 13) : ""}
        </li>
      ));
    }
  };

  startToggleModal = async (order) => {
    if (order == null) {
      this.setState({
        toggleModal: !this.state.toggleModal,
        order: null,
      });
    } else {
      this.setState({
        toggleModal: !this.state.toggleModal,
        order,
      });
    }
  };

  _handleImgChange = async (e, i) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ image_loader: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImage(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ image_loader: false });
    }
  };

  handleSearchBarChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { open } = this.state;
    const { productRequests } = this.props;
    let orders = [];
    if (productRequests.length > 0) {
      orders = productRequests.sort((a, b) => b.time - a.time);
    }
    console.log(this.props);
    return (
      <Fragment>
        <UpdateRequestModal
          toggleModal={this.state.toggleModal}
          startToggleModal={this.startToggleModal}
          order={this.state.order}
        />

        <Breadcrumb
          title={this.props.match.params.orderStatus}
          parent="Product Requests"
        />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h5>{this.props.match.params.orderStatus} Requests</h5>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    {" "}
                    <li
                      style={{
                        border: "1px solid gainsboro",
                        borderRadius: "5rem",
                        padding: "0px 20px",
                        background: "whitesmoke",
                        marginRight: "20px",
                      }}
                    >
                      <form
                        className="form-inline search-form"
                        onSubmit={this.handleSubmit}
                      >
                        <div
                          // className="form-group"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <input
                            className={
                              "form-control-plaintext " +
                              (this.state.searchbar ? "open" : "")
                            }
                            name="searchFor"
                            value={this.state.searchFor}
                            type="search"
                            placeholder="Search Orders"
                            style={{ paddingLeft: 10 }}
                            onChange={this.handleSearchBarChange}
                          />
                          <span
                          // className="d-sm-none mobile-search"
                          // onClick={() => this.handleSearchClick()}
                          >
                            <Search
                              style={{
                                marginTop: "5px",
                                borderLeft: "1px solid gainsboro",
                                paddingLeft: "7px",
                                color: "gray",
                              }}
                            />
                          </span>
                        </div>
                      </form>
                    </li>
                    <li>
                      {" "}
                      <button
                        className="btn"
                        data-toggle="modal"
                        data-target="#personalInfoModal"
                        style={{
                          backgroundColor: "rgb(0, 37, 76)",
                          border: "none",
                          color: "white",
                          fontSize: 11,
                        }}
                        onClick={() => {
                          this.setState({
                            open: false,
                            searchFor: "",
                            toggleModal: true,
                            toggleDeleteModal: true,
                            order: null,
                            imageUrl: man,
                            date: new Date().toLocaleDateString("en-GB"),
                            productQuantity: "",
                            productUrl: "",
                            productVariant: "",
                            productType: "",
                            country: "",
                            store: "",
                            image_loader: false,
                            submit_loader: false,
                            cursor: -1,
                            customer: "",
                            customerUid: "",
                            showSuggestion: false,
                          });
                        }}
                      >
                        Create Product Request
                      </button>
                    </li>
                  </div>
                </div>
                <div className="card-body">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <Datatable
                      startToggleModal={this.startToggleModal}
                      startDeleteToggleModal={this.startDeleteToggleModal}
                      history={this.props.history}
                      orderStatus={this.props.match.params.orderStatus}
                      multiSelectOption={false}
                      myData={
                        !this.state.searchFor
                          ? orders
                          : orders.filter(
                              (order) =>
                                (order.orderedDate &&
                                  order.orderedDate.includes(
                                    this.state.searchFor.toLowerCase()
                                  )) ||
                                (order.trackingNo &&
                                  order.trackingNo
                                    .toLowerCase()
                                    .includes(
                                      this.state.searchFor.toLowerCase()
                                    )) ||
                                (order.orderId &&
                                  order.orderId
                                    .toString()
                                    .includes(this.state.searchFor)) ||
                                (order.orderNumber &&
                                  order.orderNumber
                                    .toLowerCase()
                                    .includes(
                                      this.state.searchFor.toLowerCase()
                                    ))
                            )
                      }
                      pageSize={50}
                      pagination={true}
                      class="-striped -highlight"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="personalInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "95%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Create New Product Request
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <form
                    onSubmit={this.handleSubmit}
                    className="rounded-field mt-2"
                  >
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          SELECT CUSTOMER
                        </label>
                        <input
                          title="Please choose a package"
                          style={{ padding: 18 }}
                          type="text"
                          name="customer"
                          className="form-control"
                          placeholder="Enter customer Id"
                          aria-required="true"
                          aria-invalid="false"
                          onChange={this.handleChangeCustomer}
                          value={this.state.customer}
                          required
                          autoComplete="off"
                          onKeyDown={this.handleKeyDown}
                        />
                        {this.state.customer && (
                          <ul
                            className="below-searchbar-recommendation"
                            style={{
                              display: this.state.showSuggestion
                                ? "flex"
                                : "none",
                            }}
                          >
                            {this.renderShowSuggestion()}
                          </ul>
                        )}
                      </div>
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          SELECT STORE
                        </label>

                        <select
                          className="custom-select"
                          name="store"
                          value={this.state.store}
                          onChange={this.handleChange}
                          required
                          style={{ height: 50, color: "gray" }}
                        >
                          <option value="">Select Store</option>
                          <option value="AliExpress">AliExpress</option>
                          <option value="Gearbest">Gearbest</option>
                          <option value="Taobao">Taobao</option>
                          <option value="Flipkart">Flipkart</option>
                          <option value="Amazon (India)">Amazon (India)</option>
                          <option value="Amazon (USA)">Amazon (USA)</option>
                          <option value="Amazon (UAE)">Amazon (UAE)</option>
                          <option value="Best Buy">Best Buy</option>
                          <option value="Alibaba">Alibaba</option>
                          <option value="Pingduoduo">Pingduoduo</option>
                          <option value="Taobao (Direct)">
                            Taobao (Direct)
                          </option>
                          <option value="Walmart">Walmart</option>
                          <option value="eBay">eBay</option>
                          <option value="JLCPCB">JLCPCB</option>
                          <option value="Noon">Noon</option>
                          <option value="Myntra">Myntra</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          ORIGIN COUNTRY
                        </label>
                        <select
                          className="custom-select"
                          name="country"
                          value={this.state.country}
                          onChange={this.handleChange}
                          required
                          style={{ height: 50, color: "gray" }}
                        >
                          <option value="">Select Country</option>
                          <option value="china">China</option>
                          <option value="uk">UK</option>
                          <option value="usa">Usa</option>
                          <option value="hongkong">Hongkong</option>
                          <option value="thailand">Thailand</option>
                          <option value="india">India</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          PRODUCT TYPE
                        </label>

                        <select
                          className="custom-select"
                          name="productType"
                          value={this.state.productType}
                          onChange={this.handleChange}
                        >
                          <option value="">
                            Select Product Type (shipping rate for 5kg+)
                          </option>

                          {this.props.d2dRates.map((rate) => (
                            <option
                              value={`${rate.id}-${rate.eleven}`}
                              key={rate.id}
                            >
                              {rate.id}-{rate.eleven}Tk/Kg
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          PRODUCT LINK
                        </label>
                        <textarea
                          type="text"
                          name="productUrl"
                          value={this.state.productUrl}
                          onChange={this.handleChange}
                          className="form-control"
                          placeholder="Enter product Url here"
                        />
                      </div>
                    </div>

                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          PRODUCT QUANTITY
                        </label>

                        <input
                          type="text"
                          name="productQuantity"
                          value={this.state.productQuantity}
                          onChange={this.handleChange}
                          className="form-control"
                          placeholder="Product Quantity"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          PRODUCT DESCRIPTION
                        </label>

                        <textarea
                          style={{
                            borderRadius: 5,
                            height: 100,
                          }}
                          type="text"
                          name="productVariant"
                          value={this.state.productVariant}
                          onChange={this.handleChange}
                          className="form-control"
                          placeholder="Product description (color,size,variant,quanity etc)"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          UPLOAD IMAGE
                        </label>

                        <div
                          className="box-input-file"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          {this.state.image_loader ? (
                            <div
                              className="spinner-border"
                              role="status"
                              style={{
                                color: "purple",
                                paddingTop: "25px",
                                backgroundColor: "white",
                                width: 150,
                                height: 150,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                border: "1px solid gainsboro",
                                borderRadius: 8,
                              }}
                            >
                              <div
                                style={{
                                  marginTop: 30,
                                  padding: 0,
                                  marginRight: 20,
                                }}
                              >
                                <CircleLoader
                                  loading={this.state.image_loader}
                                  color="blue"
                                  size={35}
                                />
                              </div>
                            </div>
                          ) : (
                            <>
                              <img
                                className="img-100 lazyloaded blur-up"
                                src={this.state.imageUrl}
                                alt="#"
                                style={{
                                  zIndex: 6,
                                  cursor: "pointer",
                                  maxWidth: 150,
                                  minWidth: 150,
                                  minHeight: 150,
                                  maxHeight: 150,
                                  border: "1px solid gainsboro",
                                  borderRadius: 8,
                                }}
                                onClick={() => {
                                  document
                                    .getElementById("upload-image-input")
                                    .click();
                                }}
                              />
                              <input
                                id="upload-image-input"
                                className="upload"
                                type="file"
                                style={{
                                  position: "absolute",
                                  zIndex: 5,
                                  maxWidth: "50px",
                                  marginTop: "20px",
                                }}
                                onChange={(e) => this._handleImgChange(e, 0)}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.handleBookNowSubmit();
                  }}
                >
                  Create Product Request
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productRequests: state.productRequests.productRequests,
    d2dRates: state.d2dRates.d2dRates,
    allUsers: state.users.users,
  };
};

export default connect(mapStateToProps, {
  getAllProductRequestsRedux,
  getAllUsersRedux,
  getAllD2DRatesRedux,
  setProductRequestRedux,
})(New);
