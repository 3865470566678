import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Datatable from "./datatable.jsx";
import {
  getAllShipmentRequestsRedux,
  getAllWarehousesRedux,
  getAllD2DRatesRedux,
  getAllUsersRedux,
  setShipForMeRedux,
} from "../../actions/index";
import { uploadImage } from "../../firebase/firebase.utils.js";
import { Link } from "react-router-dom";
import UpdateRequestModal from "./updateRequestModal";
import man from "../../components/products/physical/plus image.jpeg";
import { connect } from "react-redux";
import { Search } from "react-feather";
import { HashLoader, CircleLoader } from "react-spinners";
import "./updateRequestModal.css";
export class New extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      searchFor: "",
      toggleModal: true,
      toggleDeleteModal: true,
      singleRequest: null,

      cursor: -1,
      customer: "",
      customerUid: "",
      showSuggestion: false,

      imageUrl: man,
      visual: "INPUT",
      shipFrom: "",
      method: "",
      productType: "",
      methodError: false,
      shipFromError: false,
      productTypeError: false,
      weightError: false,
      submit_loader: false,
      submit_loader2: false,
      result: null,
      weight: "",
      date: new Date().toLocaleDateString("en-GB"),
      ctnQuantity: "",
      totalCbm: "",
      productContains: "liquid",
      productBrand: "Brand",
      productsTotalCost: "",
      shipTo: "",
      shipTo2: "",
      validTo: "",
      shipmentMethod: "D2D",
      productQuantity: "",
      othersProductName: "",
      productUrl: "",
      productVariant: "",
      image_loader: false,
    };
  }

  componentDidMount = async () => {
    this.props.getAllWarehousesRedux();
    this.props.getAllUsersRedux();
    if (this.props.match.params.bookingStatus === "new") {
      this.props.getAllShipmentRequestsRedux("Pending");
    } else if (this.props.match.params.bookingStatus === "rates-given") {
      this.props.getAllShipmentRequestsRedux("Rates Provided");
    } else if (
      this.props.match.params.bookingStatus === "received-in-warehouse"
    ) {
      this.props.getAllShipmentRequestsRedux("Received in Warehouse");
    } else if (this.props.match.params.bookingStatus === "ready-for-fly") {
      this.props.getAllShipmentRequestsRedux("Ready for fly");
    } else if (this.props.match.params.bookingStatus === "bangladesh-customs") {
      this.props.getAllShipmentRequestsRedux("Bangladesh customs");
    } else if (this.props.match.params.bookingStatus === "custom-released") {
      this.props.getAllShipmentRequestsRedux("Bangladesh warehouse");
    } else if (this.props.match.params.bookingStatus === "paicart-warehouse") {
      this.props.getAllShipmentRequestsRedux("Paicart warehouse");
    } else if (this.props.match.params.bookingStatus === "delivered") {
      this.props.getAllShipmentRequestsRedux("Delivered");
    } else if (this.props.match.params.bookingStatus === "rejected") {
      this.props.getAllShipmentRequestsRedux("Reject");
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.match.params.bookingStatus !==
      nextProps.match.params.bookingStatus
    ) {
      if (nextProps.match.params.bookingStatus === "new") {
        this.props.getAllShipmentRequestsRedux("Pending");
      } else if (nextProps.match.params.bookingStatus === "rates-given") {
        this.props.getAllShipmentRequestsRedux("Rates Provided");
      } else if (
        nextProps.match.params.bookingStatus === "received-in-warehouse"
      ) {
        this.props.getAllShipmentRequestsRedux("Received in Warehouse");
      } else if (nextProps.match.params.bookingStatus === "ready-for-fly") {
        this.props.getAllShipmentRequestsRedux("Ready for fly");
      } else if (
        nextProps.match.params.bookingStatus === "bangladesh-customs"
      ) {
        this.props.getAllShipmentRequestsRedux("Bangladesh customs");
      } else if (nextProps.match.params.bookingStatus === "custom-released") {
        this.props.getAllShipmentRequestsRedux("Bangladesh warehouse");
      } else if (nextProps.match.params.bookingStatus === "paicart-warehouse") {
        this.props.getAllShipmentRequestsRedux("Paicart warehouse");
      } else if (nextProps.match.params.bookingStatus === "delivered") {
        this.props.getAllShipmentRequestsRedux("Delivered");
      } else if (nextProps.match.params.bookingStatus === "rejected") {
        this.props.getAllShipmentRequestsRedux("Reject");
      }
    }
  };

  startToggleModal = async (requestObj) => {
    if (requestObj == null) {
      this.setState({
        toggleModal: !this.state.toggleModal,
        singleRequest: null,
      });
    } else {
      console.log(requestObj);
      this.setState({
        toggleModal: !this.state.toggleModal,
        singleRequest: requestObj,
      });
    }
  };
  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      this.props.getAllD2DRatesRedux(this.state.method, this.state.shipFrom);
    });
  };
  handleChange2 = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleChangeBooking = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handleChangeBooking2 = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      const day = parseInt(this.state.shipTo.split("-")[2]);
      let newDay = parseInt(this.state.shipTo.split("-")[2]) + 7;
      let month = this.state.shipTo.split("-")[1];
      let year = this.state.shipTo.split("-")[0];
      const shipTo = `${day}-${month}-${year}`;
      this.setState({
        shipTo2: shipTo,
      });
      if (newDay > 30) {
        newDay = parseInt(newDay) - 30;
        month = parseInt(month) + 1;
        if (month > 12) {
          month = 1;
          year = parseInt(year) + 1;
        }
      }
      const newDate = `${newDay}-${month}-${year}`;

      this.setState({
        validTo: newDate,
      });
    });
  };

  _handleImgChange = async (e, i) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ image_loader: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImage(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ image_loader: false });
    }
  };

  handleChangeCustomer = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, showSuggestion: true, cursor: -1 });
  };

  handleSearchBarChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleKeyDown = (e) => {
    const { cursor } = this.state;
    let result = [];
    if (this.state.customer) {
      const suggestionById = this.props.allUsers.filter((user) =>
        user.userId.includes(this.state.customer)
      );
      const suggestionByName = this.props.allUsers.filter(
        (user) =>
          user.displayName &&
          user.displayName
            .toLowerCase()
            .includes(this.state.customer.toLowerCase())
      );
      result = [...suggestionById, ...suggestionByName].slice(0, 10);

      // arrow up/down button should select next/previous list element
      if (e.keyCode === 38 && cursor > -1) {
        this.setState((prevState) => ({
          cursor: prevState.cursor - 1,
        }));
      } else if (e.keyCode === 40 && cursor < result.length - 1) {
        this.setState((prevState) => ({
          cursor: prevState.cursor + 1,
        }));
      } else if (e.keyCode === 13 && cursor > -1) {
        this.setState({
          customer: result[cursor].userId,
          customerUid: result[cursor].uid,
          displayName: result[cursor].displayName,
          showSuggestion: false,
        });
      }
    } else {
      result = [];
    }
  };

  handleDoorToDoorSubmit = (weight, productType, allD2dRates, method) => {
    const selectedproductType = allD2dRates.find(
      (pdctType) => pdctType.id == productType
    );
    if (method === "sea") {
      if (weight <= 100) {
        const result = Math.round(weight * selectedproductType["100kg"]);
        return { result, perKg: selectedproductType["100kg"] };
      }
      if (100 < weight && weight <= 1000) {
        const result = Math.round(weight * selectedproductType["below_1000kg"]);
        return { result, perKg: selectedproductType["below_1000kg"] };
      }
      if (weight > 1000) {
        const result = Math.round(weight * selectedproductType["above_1000kg"]);
        return { result, perKg: selectedproductType["above_1000kg"] };
      }
    } else {
      if (weight <= 0.3) {
        const result = selectedproductType.parcel;
        return {
          result,
          perKg: Math.round(selectedproductType.parcel * 3.333333),
        };
      }
      if (0.3 < weight && weight <= 10) {
        const result = Math.round(weight * selectedproductType.ten);
        return { result, perKg: selectedproductType.ten };
      }
      if (weight > 10) {
        const result = Math.round(weight * selectedproductType.eleven);
        return { result, perKg: selectedproductType.eleven };
      }
    }
  };

  handleBookNowSubmit = async () => {
    this.setState({ submit_loader2: true });
    if (this.state.customerUid == "") {
      alert("Please select a customer first.");
      return;
    }
    if (this.state.image_loader) {
      alert("Please let the image to uploaded.");
      return;
    }
    if (this.state.weight && this.state.productType && this.state.method) {
      let date = new Date();
      const bookingId = `${Math.round(Math.random() * 1000000 - 1)}`;
      await this.props.setShipForMeRedux({
        ...this.state,
        date: date.toLocaleDateString("en-GB"),
        time: date.getTime(),
        userId: this.state.customerUid,
        displayName: `${this.state.customer}-${this.state.displayName}`,
        shipBy: this.state.method,
        status: "Pending",
        shipTo: this.state.shipTo2,
        shipmentStatusScore: 1,
        result: this.handleDoorToDoorSubmit(
          this.state.weight,
          this.state.productType,
          this.props.d2dRates,
          this.state.method
        ),
        bookingId,
      });
    } else {
      alert("Please fullfill all the information.");
      return;
    }

    this.setState({
      open: false,
      imageUrl: man,
      visual: "INPUT",
      shipFrom: "",
      method: "",
      productType: "",
      methodError: false,
      shipFromError: false,
      productTypeError: false,
      weightError: false,
      submit_loader: false,
      submit_loader2: false,
      result: null,
      weight: "",
      date: new Date().toLocaleDateString("en-GB"),
      ctnQuantity: "",
      totalCbm: 1,
      productContains: "liquid",
      productBrand: "Brand",
      productsTotalCost: "",
      imageUrl: "",
      shipmentMethod: "D2D",
      productQuantity: "",
      othersProductName: "",
      productUrl: "",
      productVariant: "",
      image_loader: false,
    });
  };

  renderShowSuggestion = () => {
    let suggestionArray = [];
    console.log(this.state.customer);
    if (this.state.customer) {
      console.log(this.state.customer);
      const suggestionById = this.props.allUsers.filter((user) =>
        user.userId.includes(this.state.customer)
      );
      const suggestionByName = this.props.allUsers.filter(
        (user) =>
          user.displayName &&
          user.displayName
            .toLowerCase()
            .includes(this.state.customer.toLowerCase())
      );
      suggestionArray = [...suggestionById, ...suggestionByName];
      const uniqueUser = [...new Set(suggestionArray)];
      console.log(suggestionArray);
      return uniqueUser.slice(0, 10).map((user, index) => (
        <li
          key={user.userId}
          style={{
            minWidth: "195px",
            backgroundColor: this.state.cursor == index ? "gainsboro" : "white",
          }}
          onClick={() =>
            this.setState({
              customer: user.userId,
              customerUid: user.uid,
              displayName: user.displayName,
              showSuggestion: false,
              subCategory: `${user.userId}-${user.displayName}`,
            })
          }
        >
          {user.userId}-{user.displayName ? user.displayName.slice(0, 13) : ""}
        </li>
      ));
    }
  };

  render() {
    const { open } = this.state;
    console.log(this.props);
    return (
      <Fragment>
        <UpdateRequestModal
          toggleModal={this.state.toggleModal}
          startToggleModal={this.startToggleModal}
          singleRequest={this.state.singleRequest}
        />

        <Breadcrumb
          title={this.props.match.params.bookingStatus}
          parent="Shipment Requests"
        />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h5>
                    {this.props.match.params.bookingStatus} Shipment Requests
                  </h5>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    {" "}
                    <li
                      style={{
                        border: "1px solid gainsboro",
                        borderRadius: "5rem",
                        padding: "0px 20px",
                        background: "whitesmoke",
                        marginRight: "20px",
                      }}
                    >
                      <form
                        className="form-inline search-form"
                        onSubmit={this.handleSubmit}
                      >
                        <div
                          // className="form-group"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <input
                            className={
                              "form-control-plaintext " +
                              (this.state.searchbar ? "open" : "")
                            }
                            name="searchFor"
                            value={this.state.searchFor}
                            type="search"
                            placeholder="Search Shipment Request"
                            onChange={this.handleSearchBarChange}
                          />
                          <span
                          // className="d-sm-none mobile-search"
                          // onClick={() => this.handleSearchClick()}
                          >
                            <Search
                              style={{
                                marginTop: "5px",
                                borderLeft: "1px solid gainsboro",
                                paddingLeft: "7px",
                                color: "gray",
                              }}
                            />
                          </span>
                        </div>
                      </form>
                    </li>
                    <li>
                      {" "}
                      <button
                        className="btn"
                        data-toggle="modal"
                        data-target="#personalInfoModal"
                        style={{
                          backgroundColor: "rgb(0, 37, 76)",
                          border: "none",
                          color: "white",
                          fontSize: 11,
                        }}
                        onClick={() => {
                          this.setState({
                            imageUrl: man,
                            visual: "INPUT",
                            shipFrom: "",
                            method: "",
                            productType: "",
                            methodError: false,
                            shipFromError: false,
                            productTypeError: false,
                            weightError: false,
                            submit_loader: false,
                            submit_loader2: false,
                            result: null,
                            weight: "",
                            date: new Date().toLocaleDateString("en-GB"),
                            ctnQuantity: "",
                            totalCbm: "",
                            productContains: "liquid",
                            productBrand: "Brand",
                            productsTotalCost: "",
                            shipTo: "",
                            shipTo2: "",
                            validTo: "",
                            shipmentMethod: "D2D",
                            productQuantity: "",
                            othersProductName: "",
                            productUrl: "",
                            productVariant: "",
                            image_loader: false,
                          });
                        }}
                      >
                        Create New Shipment
                      </button>
                    </li>
                  </div>
                </div>
                <div className="card-body">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <Datatable
                      startToggleModal={this.startToggleModal}
                      startDeleteToggleModal={this.startDeleteToggleModal}
                      history={this.props.history}
                      props={this.props}
                      bookingStatus={this.props.match.params.bookingStatus}
                      multiSelectOption={false}
                      myData={
                        !this.state.searchFor
                          ? this.props.allShipmentRequests
                          : this.props.allShipmentRequests.filter(
                              (productObj) =>
                                productObj.date.includes(
                                  this.state.searchFor.toLowerCase()
                                ) ||
                                (productObj.trackingNo &&
                                  productObj.trackingNo
                                    .toLowerCase()
                                    .includes(
                                      this.state.searchFor.toLowerCase()
                                    ))
                            )
                      }
                      pageSize={50}
                      pagination={true}
                      class="-striped -highlight"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}
        <div
          className="modal fade"
          id="personalInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "95%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Create New Shipment Request
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <form
                    onSubmit={this.handleSubmit}
                    className="rounded-field mt-2"
                  >
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          SELECT CUSTOMER
                        </label>
                        <input
                          title="Please choose a package"
                          style={{ padding: 18 }}
                          type="text"
                          name="customer"
                          className="form-control"
                          placeholder="Enter customer Id"
                          aria-required="true"
                          aria-invalid="false"
                          onChange={this.handleChangeCustomer}
                          value={this.state.customer}
                          required
                          autoComplete="off"
                          onKeyDown={this.handleKeyDown}
                        />
                        {this.state.customer && (
                          <ul
                            className="below-searchbar-recommendation"
                            style={{
                              display: this.state.showSuggestion
                                ? "flex"
                                : "none",
                            }}
                          >
                            {this.renderShowSuggestion()}
                          </ul>
                        )}
                      </div>
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          METHOD (Sea/Air)
                        </label>

                        <select
                          className="custom-select"
                          name="method"
                          value={this.state.method}
                          onChange={this.handleChange}
                        >
                          <option value="">Choose Method</option>
                          <option value="sea">Sea</option>
                          <option value="air">Air</option>
                        </select>
                      </div>
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          ORIGIN OF SHIPMENT
                        </label>

                        <select
                          className="custom-select"
                          name="shipFrom"
                          value={this.state.shipFrom}
                          onChange={this.handleChange}
                        >
                          <option value="">Select Country</option>
                          <option value="china">China</option>
                          <option value="uk">UK</option>
                          <option value="usa">Usa</option>
                          <option value="hongkong">Hongkong</option>
                          <option value="thailand">Thailand</option>
                          {this.state.method === "air" && (
                            <>
                              <option value="india">India</option>
                            </>
                          )}
                        </select>
                      </div>
                    </div>

                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          TOTAL WEIGHT
                        </label>

                        <input
                          type="number"
                          name="weight"
                          value={this.state.weight}
                          onChange={(event) => {
                            this.setState({
                              weight: event.target.value,
                            });
                          }}
                          className="form-control"
                          placeholder="Enter Weight"
                        />
                      </div>
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          PRODUCT TYPE
                        </label>

                        <select
                          className="custom-select"
                          name="productType"
                          value={this.state.productType}
                          onChange={this.handleChange}
                        >
                          <option value="">Select Product</option>

                          {this.props.d2dRates.map((rate) => (
                            <option
                              value={`${rate.id}-${rate.eleven}`}
                              key={rate.id}
                            >
                              {rate.id}-{rate.eleven}Tk/Kg
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          PRODUCT LINK (optional)
                        </label>
                        <textarea
                          type="text"
                          name="productUrl"
                          value={this.state.productUrl}
                          onChange={this.handleChangeBooking}
                          className="form-control"
                          placeholder="Enter product Url here"
                        />
                      </div>
                    </div>
                    <div
                      className="form-row"
                      style={{
                        color: "#555",
                        marginTop: 15,
                        marginBottom: 10,
                        borderBottom: "1px solid gray",
                      }}
                    >
                      Shipping Information
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          CARTON QUANTITY
                        </label>

                        <input
                          type="text"
                          name="ctnQuantity"
                          value={this.state.ctnQuantity}
                          onChange={this.handleChangeBooking}
                          className="form-control"
                          placeholder="Carton Quantity"
                        />
                      </div>
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          PRODUCT QUANTITY
                        </label>

                        <input
                          type="text"
                          name="productQuantity"
                          value={this.state.productQuantity}
                          onChange={this.handleChangeBooking}
                          className="form-control"
                          placeholder="Product Quantity"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          PRODUCT TOTAL COST
                        </label>

                        <input
                          type="text"
                          name="productsTotalCost"
                          value={this.state.productsTotalCost}
                          onChange={this.handleChangeBooking}
                          className="form-control"
                          placeholder="Products total cost"
                        />
                      </div>
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          PRODUCT NAME
                        </label>

                        <input
                          type="text"
                          name="othersProductName"
                          value={this.state.othersProductName}
                          onChange={this.handleChangeBooking}
                          className="form-control"
                          placeholder="Product Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          PRODUCT CONTAINS
                        </label>

                        <select
                          className="custom-select"
                          name="productContains"
                          value={this.state.productContains}
                          onChange={this.handleChangeBooking}
                          required
                        >
                          <option value="liquid">liquid</option>
                          <option value="powder">powder</option>
                          <option value="battery">battery</option>
                          <option value="none">none</option>
                        </select>
                      </div>
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          BRAND/NON-BRAND
                        </label>

                        <select
                          className="custom-select"
                          name="productBrand"
                          value={this.state.productBrand}
                          onChange={this.handleChangeBooking}
                          required
                        >
                          <option value="Brand">Brand</option>
                          <option value="Non Brand">Non Brand</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          Total CBM (in cm3)
                        </label>
                        <input
                          type="text"
                          name="totalCbm"
                          value={this.state.totalCbm}
                          onChange={this.handleChangeBooking}
                          className="form-control"
                          placeholder="Enter total CBM (height x width x length) in cm"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          SHIP TO
                        </label>
                        <input
                          type="date"
                          name="shipTo"
                          value={this.state.shipTo}
                          onChange={(e) => {
                            this.handleChangeBooking2(e);
                          }}
                          className="form-control"
                          required
                          min={new Date().toISOString().split("T")[0]}
                        />
                      </div>
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          VALID TO
                        </label>

                        <input
                          type="text"
                          name="validTo"
                          value={this.state.validTo}
                          className="form-control"
                          placeholder="Shipping Deadline"
                          required
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          ADDITIONAL INFORMATION
                        </label>

                        <textarea
                          style={{
                            borderRadius: 5,
                            height: 80,
                          }}
                          type="text"
                          name="productVariant"
                          value={this.state.productVariant}
                          onChange={this.handleChangeBooking}
                          className="form-control"
                          placeholder="Product description (color,size,variant,quanity etc)"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row mb-2">
                      <div className="col">
                        <label
                          style={{
                            color: "#555",
                            marginBottom: 0,
                            fontSize: 13,
                          }}
                        >
                          UPLOAD IMAGE
                        </label>

                        <div
                          className="box-input-file"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          {this.state.image_loader ? (
                            <div
                              className="spinner-border"
                              role="status"
                              style={{
                                color: "purple",
                                paddingTop: "25px",
                                backgroundColor: "white",
                                width: 150,
                                height: 150,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                border: "1px solid gainsboro",
                                borderRadius: 8,
                              }}
                            >
                              <div
                                style={{
                                  marginTop: 30,
                                  padding: 0,
                                  marginRight: 20,
                                }}
                              >
                                <CircleLoader
                                  loading={this.state.image_loader}
                                  color="blue"
                                  size={35}
                                />
                              </div>
                            </div>
                          ) : (
                            <>
                              <img
                                className="img-100 lazyloaded blur-up"
                                src={this.state.imageUrl}
                                alt="#"
                                style={{
                                  zIndex: 6,
                                  cursor: "pointer",
                                  maxWidth: 150,
                                  minWidth: 150,
                                  minHeight: 150,
                                  maxHeight: 150,
                                  border: "1px solid gainsboro",
                                  borderRadius: 8,
                                }}
                                onClick={() => {
                                  document
                                    .getElementById("upload-image-input")
                                    .click();
                                }}
                              />
                              <input
                                id="upload-image-input"
                                className="upload"
                                type="file"
                                style={{
                                  position: "absolute",
                                  zIndex: 5,
                                  maxWidth: "50px",
                                  marginTop: "20px",
                                }}
                                onChange={(e) => this._handleImgChange(e, 0)}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.handleBookNowSubmit();
                  }}
                >
                  Create Shipment Request
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allShipmentRequests: state.productRequests.shipmentRequests,
    warehouses: state.warehouses.warehouses,
    d2dRates: state.d2dRates.d2dRates,
    allUsers: state.users.users,
  };
};

export default connect(mapStateToProps, {
  getAllShipmentRequestsRedux,
  getAllWarehousesRedux,
  getAllD2DRatesRedux,
  getAllUsersRedux,
  setShipForMeRedux,
})(New);
