import {
  getAllProductRequests,
  getAllShipmentRequests,
  getAllUsers,
  updateProductRequest,
  updateShipmentRequest,
  deleteShipmentRequest,
  getAllPaymentRequest,
  getAllPaymentRequestOrder,
  getAllRefundRequestOrder,
  updatePaymentRequestStatus,
  updatePaymentRequestOrderStatus,
  updateRefundRequestOrderStatus,
  makePayment,
  getCurrency,
  getAllOrdersApi,
  getAllRooms,
  updateOrderApi,
  deleteOrderApi,
  updateProduct,
  deleteOrder,
  createIntro,
  getAllIntros,
  deleteProduct,
  readAllMessage,
  getAllD2DRates,
  updateD2DRates,
  uploadD2DRates,
  deleteD2DRates,
  getAllCoupons,
  uploadCoupon,
  updateCoupon,
  deleteCoupon,
  getAllPartials,
  uploadPartial,
  updatePartial,
  deletePartial,
  getAllWarehouses,
  uploadWarehouse,
  updateWarehouse,
  deleteWarehouse,
  getAllBanners,
  uploadBanner,
  updateBanner,
  deleteBanner,
  getAllCampaigns,
  uploadCampaign,
  updateCampaign,
  deleteCampaign,
  getAllHomeCategory,
  uploadHomeCategory,
  updateHomeCategory,
  deleteHomeCategory,
  setShipForMe,
  getSingleShipForMe,
  getSingleOrderApi,
  updateUserStatus,
  getSingleUser,
  getAllShipForMeOfSingleUser,
  getAllOrderOfSingleUser,
  getAllProductRequestOfSingleUser,
  getAllPaymentOfSingleUser,
  createNotice,
  updateNotice,
  getAllNotices,
  deleteProductRequest,
  getSingleProductRequest,
  setProductRequest,
} from "../firebase/firebase.utils";
import Axios from "axios";
export const setAllOrders = (ordersArray) => ({
  type: "SET_ALL_ORDERS",
  payload: ordersArray,
});

export const appendMessagesRedux = (messages) => ({
  type: "APPEND_MESSAGES",
  payload: messages,
});

export const setAllPayments = (paymentsArray) => ({
  type: "SET_ALL_PAYMENTS",
  payload: paymentsArray,
});
export const setAllAdmins = (adminsArray) => ({
  type: "SET_ALL_ADMINS",
  payload: adminsArray,
});
export const setCurrentAdmin = (adminObj) => ({
  type: "SET_CURRENT_ADMIN",
  payload: adminObj,
});

// notice
export const createNoticeRedux = (noticeObj) => async (dispatch) => {
  const createdNoticeObj = await createNotice(noticeObj);
  dispatch({ type: "CREATE_NOTICE", payload: createdNoticeObj });
};
export const getAllNoticesRedux = () => async (dispatch) => {
  const noticesArray = await getAllNotices();
  dispatch({ type: "GET_ALL_NOTICES", payload: noticesArray });
};

export const updateNoticeRedux = (noticeObj) => async (dispatch) => {
  const updatedNoticeObj = await updateNotice(noticeObj);
  dispatch({ type: "UPDATE_NOTICE", payload: updatedNoticeObj });
};

export const setAllProducts = (productsArray) => ({
  type: "SET_ALL_PRODUCTS",
  payload: productsArray,
});

export const rechargeAdminredux = (adminIdArray, balance) => {
  return {
    type: "RECHARGE_ADMIN",
    payload: {
      adminIdArray,
      balance,
    },
  };
};

export const updateProfileImageRedux = (imgUrl) => {
  return {
    type: "UPDATE_PROFILE_IMAGE",
    payload: imgUrl,
  };
};

export const getAllUsersRedux = () => async (dispatch) => {
  const allUsers = await getAllUsers();
  dispatch({ type: "GET_ALL_USERS", payload: allUsers });
};

export const updateUserStatusRedux = (userObj) => async (dispatch) => {
  const updatedUserObj = await updateUserStatus(userObj);
  dispatch({
    type: "UPDATE_USER_STATUS",
    payload: updatedUserObj,
  });
};
export const getSingleUserRedux = (userId) => async (dispatch) => {
  const updatedUserObj = await getSingleUser(userId);
  dispatch({
    type: "GET_SINGLE_USER",
    payload: updatedUserObj,
  });
};
export const selectRoomRedux = (roomId) => async (dispatch) => {
  await readAllMessage(roomId);
  dispatch({
    type: "SELECT_ROOM",
    payload: roomId,
  });
};

export const setShipForMeRedux = (bookingObj) => async (dispatch) => {
  const uploadedBookingObj = await setShipForMe(bookingObj);
  dispatch({
    type: "SET_SHIP_FOR_ME",
    payload: uploadedBookingObj,
  });
};

export const getAllProductRequestsRedux = (status) => async (dispatch) => {
  const requestsArray = await getAllProductRequests(status);
  dispatch({ type: "GET_ALL_PRODUCT_REQUESTS", payload: requestsArray });
};

export const getAllShipmentRequestsRedux = (status) => async (dispatch) => {
  const requestsArray = await getAllShipmentRequests(status);
  dispatch({ type: "GET_ALL_SHIPMENT_REQUESTS", payload: requestsArray });
};
export const getAllShipForMeOfSingleUserRedux =
  (userId) => async (dispatch) => {
    const requestsArray = await getAllShipForMeOfSingleUser(userId);
    dispatch({
      type: "GET_ALL_SHIP_FOR_ME_OF_SINGLE_USER",
      payload: requestsArray,
    });
  };
export const getAllOrderOfSingleUserRedux = (userId) => async (dispatch) => {
  const ordersArray = await getAllOrderOfSingleUser(userId);
  dispatch({ type: "GET_ALL_ORDER_OF_SINGLE_USER", payload: ordersArray });
};
export const getAllProductRequestOfSingleUserRedux =
  (userId) => async (dispatch) => {
    const ordersArray = await getAllProductRequestOfSingleUser(userId);
    dispatch({
      type: "GET_ALL_PRODUCT_REQUEST_OF_SINGLE_USER",
      payload: ordersArray,
    });
  };
export const getAllPaymentOfSingleUserRedux = (userId) => async (dispatch) => {
  const ordersArray = await getAllPaymentOfSingleUser(userId);
  dispatch({ type: "GET_ALL_PAYMENT_OF_SINGLE_USER", payload: ordersArray });
};

export const updateProductRequestRedux = (requestObj) => async (dispatch) => {
  const updatedRequest = await updateProductRequest(requestObj);
  dispatch({ type: "UPDATE_PRODUCT_REQUEST", payload: updatedRequest });
};
export const updateProductRedux = (productObj) => async (dispatch) => {
  const updatedProduct = await updateProduct(productObj);
  dispatch({ type: "UPDATE_PRODUCT", payload: updatedProduct });
};
export const deleteProductRedux = (id) => async (dispatch) => {
  await deleteProduct(id);
  dispatch({ type: "DELETE_PRODUCT", payload: id });
};

export const updateShipmentRequestRedux = (requestObj) => async (dispatch) => {
  const updatedRequest = await updateShipmentRequest(requestObj);
  dispatch({ type: "UPDATE_SHIPMENT_REQUEST", payload: updatedRequest });
};
export const deleteShipmentRequestRedux = (requestObj) => async (dispatch) => {
  await deleteShipmentRequest(requestObj);
  dispatch({ type: "DELETE_SHIPMENT_REQUEST", payload: requestObj });
};

export const getSingleShipForMeRedux = (bookingId) => async (dispatch) => {
  const shipForMeObj = await getSingleShipForMe(bookingId);
  dispatch({
    type: "GET_SINGLE_SHIP_FOR_ME",
    payload: shipForMeObj,
  });
};
export const getSingleOrderApiRedux = (bookingId) => async (dispatch) => {
  const orderObj = await getSingleOrderApi(bookingId);
  dispatch({
    type: "GET_SINGLE_ORDER_API",
    payload: orderObj,
  });
};
export const getSingleProductRequestRedux = (bookingId) => async (dispatch) => {
  const orderObj = await getSingleProductRequest(bookingId);
  dispatch({
    type: "GET_SINGLE_PRODUCT_REQUEST",
    payload: orderObj,
  });
};

export const getAllPaymentRequestRedux = () => async (dispatch) => {
  const paymentRequestArray = await getAllPaymentRequest();
  dispatch({ type: "GET_ALL_PAYMENT_REQUEST", payload: paymentRequestArray });
};
export const getAllPaymentRequestOrderRedux = () => async (dispatch) => {
  const paymentRequestOrderArray = await getAllPaymentRequestOrder();
  dispatch({
    type: "GET_ALL_PAYMENT_REQUEST_ORDER",
    payload: paymentRequestOrderArray,
  });
};
export const getAllRefundRequstOrderRedux = () => async (dispatch) => {
  const refundRequestOrderArray = await getAllRefundRequestOrder();
  dispatch({
    type: "GET_ALL_REFUND_REQUEST_ORDER",
    payload: refundRequestOrderArray,
  });
};

export const updatePaymentRequestStatusRedux =
  (paymentRequestObj) => async (dispatch) => {
    const updatedPaymentRequestObj = await updatePaymentRequestStatus(
      paymentRequestObj
    );
    dispatch({
      type: "UPDATE_PAYMENT_REQUEST_STATUS",
      payload: updatedPaymentRequestObj,
    });
  };
export const updatePaymentRequestOrderStatusRedux =
  (paymentRequestObj) => async (dispatch) => {
    const updatedPaymentRequestObj = await updatePaymentRequestOrderStatus(
      paymentRequestObj
    );
    dispatch({
      type: "UPDATE_PAYMENT_REQUEST_ORDER_STATUS",
      payload: updatedPaymentRequestObj,
    });
  };
export const updateRefundRequestOrderStatusRedux =
  (refundRequestObj) => async (dispatch) => {
    const updatedRefundRequestObj = await updateRefundRequestOrderStatus(
      refundRequestObj
    );
    dispatch({
      type: "UPDATE_REFUND_REQUEST_ORDER_STATUS",
      payload: updatedRefundRequestObj,
    });
  };

export const getAllIntrosRedux = () => async (dispatch) => {
  const introsArray = await getAllIntros();
  dispatch({ type: "GET_ALL_INTROS", payload: introsArray });
};

export const createIntroRedux = (introObj) => async (dispatch) => {
  const createdIntroObj = await createIntro(introObj);
  dispatch({ type: "CREATE_INTRO", payload: createdIntroObj });
};

export const makePaymentRedux =
  (total, invoicesToPay, currentUser, admin, parcelsArray, paymentMethod) =>
  async (dispatch) => {
    const updatedOrdersArray = await makePayment(
      total,
      invoicesToPay,
      currentUser,
      admin,
      parcelsArray,
      paymentMethod
    );
    dispatch({
      type: "UPDATE_MULTIPLE_ORDERS",
      payload: updatedOrdersArray,
    });
  };

export const getCurrencyRedux = () => async (dispatch) => {
  const currency = await getCurrency();
  dispatch({ type: "GET_CURRENCY_REDUX", payload: currency });
};

export const getAllOrdersApiRedux = (orderStatus) => async (dispatch) => {
  const ordersApiArray = await getAllOrdersApi(orderStatus);
  dispatch({
    type: "GET_ALL_ORDERS_API_OF_SINGLE_STATUS",
    payload: ordersApiArray,
  });
};
export const getAllRoomsRedux = (rooms) => async (dispatch) => {
  // const rooms = await getAllRooms();
  dispatch({
    type: "GET_ALL_ROOMS",
    payload: rooms,
  });
};

export const updateOrderApiRedux = (order) => async (dispatch) => {
  const orderObj = await updateOrderApi(order);
  dispatch({
    type: "UPDATE_ORDER_API",
    payload: orderObj,
  });
};
export const deleteOrderApiRedux = (order) => async (dispatch) => {
  await deleteOrderApi(order);
  dispatch({
    type: "DELETE_ORDER_API",
    payload: order,
  });
};
export const deleteProductRequestRedux = (order) => async (dispatch) => {
  await deleteProductRequest(order);
  dispatch({
    type: "DELETE_PRODUCT_REQUEST",
    payload: order,
  });
};

// D2D Rates
export const getAllD2DRatesRedux =
  (freightType, country) => async (dispatch) => {
    const allD2DRatesAirArray = await getAllD2DRates(freightType, country);
    dispatch({
      type: "GET_ALL_D2D_RATES",
      payload: allD2DRatesAirArray,
    });
  };

export const setProductRequestRedux = (bookingObj) => async (dispatch) => {
  const uploadedBookingObj = await setProductRequest(bookingObj);
  dispatch({
    type: "SET_PRODUCT_REQUEST",
    payload: uploadedBookingObj,
  });
};

export const uploadD2DRatesRedux =
  (freightType, country, productTypeObj) => async (dispatch) => {
    const uploadedD2DRatesObj = await uploadD2DRates(
      freightType,
      country,
      productTypeObj
    );
    dispatch({
      type: "UPLOAD_D2D_RATES",
      payload: uploadedD2DRatesObj,
    });
  };

export const updateD2DRatesRedux =
  (freightType, country, productTypeObj) => async (dispatch) => {
    const updatedD2DRatesObj = await updateD2DRates(
      freightType,
      country,
      productTypeObj
    );
    dispatch({
      type: "UPDATE_D2D_RATES",
      payload: updatedD2DRatesObj,
    });
  };
export const deleteD2DRatesRedux =
  (freightType, country, productTypeId) => async (dispatch) => {
    await deleteD2DRates(freightType, country, productTypeId);
    dispatch({
      type: "DELETE_D2D_RATES",
      payload: { freightType, country, productTypeId },
    });
  };

export const getAllCouponsRedux = () => async (dispatch) => {
  const allCoupons = await getAllCoupons();
  dispatch({
    type: "GET_ALL_COUPONS",
    payload: allCoupons,
  });
};

export const uploadCouponRedux = (couponObj) => async (dispatch) => {
  const uploadedCouponObj = await uploadCoupon(couponObj);
  dispatch({
    type: "UPLOAD_COUPON",
    payload: uploadedCouponObj,
  });
};

export const updateCouponRedux = (couponObj) => async (dispatch) => {
  const updatedCouponObj = await updateCoupon(couponObj);
  dispatch({
    type: "UPDATE_COUPON",
    payload: updatedCouponObj,
  });
};

export const deleteCouponRedux = (id) => async (dispatch) => {
  await deleteCoupon(id);
  dispatch({
    type: "DELETE_COUPON",
    payload: id,
  });
};
export const getAllPartialsRedux = () => async (dispatch) => {
  const allCoupons = await getAllPartials();
  dispatch({
    type: "GET_ALL_PARTIALS",
    payload: allCoupons,
  });
};

export const uploadPartialRedux = (couponObj) => async (dispatch) => {
  const uploadedCouponObj = await uploadPartial(couponObj);
  dispatch({
    type: "UPLOAD_PARTIAL",
    payload: uploadedCouponObj,
  });
};

export const updatePartialRedux = (couponObj) => async (dispatch) => {
  const updatedCouponObj = await updatePartial(couponObj);
  dispatch({
    type: "UPDATE_PARTIAL",
    payload: updatedCouponObj,
  });
};

export const deletePartialRedux = (id) => async (dispatch) => {
  await deletePartial(id);
  dispatch({
    type: "DELETE_PARTIAL",
    payload: id,
  });
};
export const getAllWarehousesRedux = () => async (dispatch) => {
  const allCoupons = await getAllWarehouses();
  dispatch({
    type: "GET_ALL_WAREHOUSES",
    payload: allCoupons,
  });
};

export const uploadWarehouseRedux = (warehouseObj) => async (dispatch) => {
  const uploadedWarehouseObj = await uploadWarehouse(warehouseObj);
  dispatch({
    type: "UPLOAD_WAREHOUSE",
    payload: uploadedWarehouseObj,
  });
};

export const updateWarehouseRedux = (warehouseObj) => async (dispatch) => {
  const updatedWarehouseObj = await updateWarehouse(warehouseObj);
  dispatch({
    type: "UPDATE_WAREHOUSE",
    payload: updatedWarehouseObj,
  });
};

export const getOrderTrackingResultRedux = (trackingNo) => async (dispatch) => {
  let resultObj = null;
  if (trackingNo) {
    resultObj = await Axios.get(
      `https://alglimited.com/api/v1/orderTracking-alg/${trackingNo}`
    );
  }

  dispatch({
    type: "GET_ORDER_TRACKING_RESULT",
    payload: resultObj && resultObj.data ? resultObj.data : null,
  });
  return resultObj && resultObj.data ? resultObj.data : null;
};

export const deleteWarehouseRedux = (id) => async (dispatch) => {
  await deleteWarehouse(id);
  dispatch({
    type: "DELETE_WAREHOUSE",
    payload: id,
  });
};
export const getAllCampaignsRedux = () => async (dispatch) => {
  const allCampaigns = await getAllCampaigns();
  dispatch({
    type: "GET_ALL_CAMPAIGNS",
    payload: allCampaigns,
  });
};

export const uploadCampaignRedux = (campaignObj) => async (dispatch) => {
  const uploadedCampaignObj = await uploadCampaign(campaignObj);
  dispatch({
    type: "UPLOAD_CAMPAIGN",
    payload: uploadedCampaignObj,
  });
};

export const updateCampaignRedux = (campaignObj) => async (dispatch) => {
  const updatedCampaignObj = await updateCampaign(campaignObj);
  dispatch({
    type: "UPDATE_CAMPAIGN",
    payload: updatedCampaignObj,
  });
};

export const deleteCampaignRedux = (id) => async (dispatch) => {
  await deleteCampaign(id);
  dispatch({
    type: "DELETE_CAMPAIGN",
    payload: id,
  });
};
export const getAllHomeCategoryRedux = () => async (dispatch) => {
  const allCampaigns = await getAllHomeCategory();
  dispatch({
    type: "GET_ALL_HOME_CATEGORIES",
    payload: allCampaigns,
  });
};

export const uploadHomeCategoryRedux = (catObj) => async (dispatch) => {
  const uploadedCatObj = await uploadHomeCategory(catObj);
  dispatch({
    type: "UPLOAD_HOME_CATEGORY",
    payload: uploadedCatObj,
  });
};

export const updateHomeCategoryRedux = (catObj) => async (dispatch) => {
  const updatedCatObj = await updateHomeCategory(catObj);
  dispatch({
    type: "UPDATE_HOME_CATEGORY",
    payload: updatedCatObj,
  });
};

export const deleteHomeCategoryRedux = (id) => async (dispatch) => {
  await deleteHomeCategory(id);
  dispatch({
    type: "DELETE_HOME_CATEGORY",
    payload: id,
  });
};

export const getAllBannersRedux = () => async (dispatch) => {
  const allCats = await getAllBanners();
  dispatch({
    type: "GET_ALL_BANNERS",
    payload: allCats,
  });
};

export const uploadBannerRedux = (bannerObj) => async (dispatch) => {
  const uploadedBannerObj = await uploadBanner(bannerObj);
  dispatch({
    type: "UPLOAD_BANNER",
    payload: uploadedBannerObj,
  });
};

export const updateBannerRedux = (bannerObj) => async (dispatch) => {
  const updatedBannerObj = await updateBanner(bannerObj);
  dispatch({
    type: "UPDATE_BANNER",
    payload: updatedBannerObj,
  });
};

export const deleteBannerRedux = (bannerId) => async (dispatch) => {
  await deleteBanner(bannerId);
  dispatch({
    type: "DELETE_BANNER",
    payload: { id: bannerId },
  });
};
