import axios from "axios";
import jwt from "jsonwebtoken";

export const sendNotifications = async (token, message) => {
  const serviceAccount = {
    // Replace with your actual service account details
    client_email: "firebase-adminsdk-o3ae6@paicart.iam.gserviceaccount.com",
    private_key:
      "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCi3U3qCVfHecwE\nX0kUgnhu1W9AjnmlytpXl6YLkfOoZAaS0Rrm+3Ia+HVjTkMHZTqhhf8/SGKWCa81\n9LuIrhG6LX2UsWP9oXOQbUjIiBK5Nt/VyAo8FuoC/a0/fDvUoHmI7vsrouvhBWJs\nkb9xmohj2IWQiGd3TmAmcmiHeIGtHUUICIq5BIH/p13CokptqZVL6StU5Syf/bLH\n1jaABY9VXmO44Ui32GJcUUqGgE9Ph8r7X/zUnvC5B9tdp545D242fK5I92ky3nMG\na05nM59IOGqj8uvew6HigUbtard7Nc/jONZ0vDorvVCk7jiim1fXkoBnU3iv4v6Y\nJ3ccEg2XAgMBAAECggEASgfKiN2iVvwYLsyZTYdY8xh1r6STyzcANNysD4pSdXKy\nQj5muiQF5BiYU4GZmlz7X7ynH77pgUX5rM8IB8MIL9bJxLM1vm6LN1VVxkGEMUiu\nSmDZtiK8UIu7GqaAE9C2LfZjmZw/GFP4P12o5hzWbnxXijFWxraLoi5TMXEnkOaJ\nfb9yj+0ixoUaixRTwhsjbkvN1OZwMOeJMhqQAZnNVIFHqJ2h+S7Z8jR0eTKzGcAM\n0aEgE5l0bQDguMePdlNDjncPY5F7DIqVqR7iNgkXKABKLbgEeAx7ZRBchZnejkOh\nHx0tsUxWT+Fz+MGkzEgrzMsmhgc734CG6EvIPZueMQKBgQDNY1ml8r4wMikGbRL+\nHdnSVsBxjXIa1t79UzP3HOKLUaLk8xDc4Q7rz8g/LMuFwdlP9pZHNXjfT1Xh/UlP\nJ5na7TZGrcyXv5nj5bOQ1hqsLBtWc8bkqhscM5U4jw41rCNAnoP+d+Mn1OjtmZwB\nAhV4vFdWzKVqLVF4a/zxingeiwKBgQDK/2e4P5miztFli9iPGLyehrAScM7AqzMk\nrPUjspgEQKetXhRkAw2z7ogyQ8P6YLXmgkDaI0Td6rB/l6x0udEQCBjB4keMqvTu\nGwYwBi5fHt5GrvVoBPTNgvW8Z3v5nj4iR4XC+ynRxA4M6yIdYhdtY5jn+WZAFo/j\nSNP7EGzapQKBgAvG/KcFal59IdkafBJSR+FF1DcuszJSy+qe0MYExADVJ5AVWAwy\nY5amDYeB3BHJcdqh+zAOIuY7OGODEKcXUD07iPc4lBjHhd2JmGhfvpvM06x7axZF\notRVKONbYKc3PbZA4LTAzyfDj6/ANS/hnLWVpzR0+69A9gD/wDthg03NAoGAVmdF\nx4fSphivyjAvGiFmT3d2xDKd3iZAm5C+utYOnGjEtidAOJ2QHJEgCX0MkqrNYfL5\nFaBDdM2rGHP/pKNe2BsKJqRg3f/6Rsrb5F8NJ9GkvKNFs2PAqyTd4baOb14qhj65\nMZfkYfO4I0mF+MnCvP3SnJJsMkUoM47RI6EHJD0CgYBW/QfX+3gOExAyNJGKHKH5\ntHAE/g6rHSkyoA0HsKINkjHD0b5nDhC3y0ue0lJgy7gV8Y9rgY6Bou3cUUYxOGnQ\nAFdWYMeCCkHbIKhfE6gQyHRPLEX5JijwG9VAXVmvFrRxUA/JE0k0qBoFnIy21D3k\nOnPqV5hiRkgsvFrMzeZ1nw==\n-----END PRIVATE KEY-----\n",
  };

  const now = Math.floor(Date.now() / 1000);
  const payload = {
    iss: serviceAccount.client_email,
    scope: "https://www.googleapis.com/auth/cloud-platform",
    aud: "https://oauth2.googleapis.com/token",
    exp: now + 3600,
    iat: now,
  };
  console.log(payload);

  const signedJwt = jwt.sign(payload, serviceAccount.private_key, {
    algorithm: "RS256",
  });
  console.log(signedJwt);
  // Request the access token
  const response = await axios.post(
    "https://oauth2.googleapis.com/token",
    new URLSearchParams({
      grant_type: "urn:ietf:params:oauth:grant-type:jwt-bearer",
      assertion: signedJwt,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  console.log(response);

  let access_token = response.data.access_token;
  console.log("send notification is called!");

  // var data = JSON.stringify({
  //   data: {},
  //   notification: {
  //     title: message.title,
  //     body: message.body,
  //   },
  //   // token
  //   to: `${token}`,
  // });
  console.log(access_token);
  var data = JSON.stringify({
    message: {
      token: `${token}`,
      notification: {
        title: message.title,
        body: message.body,
      },
    },
  });

  var config = {
    method: "post",
    url: "https://fcm.googleapis.com/v1/projects/paicart/messages:send",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json; UTF-8",
    },
    data: data,
  };

  let response2 = await axios(config);
  console.log(response2);
};
